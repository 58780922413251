import { defineComponent, reactive, toRefs, onMounted, ref, nextTick } from 'vue';
import phoneBarTime from './phoneBarTime.vue';
import SPhone from 'sphone-sdk';
import { ElMessage as Message } from 'element-plus';
export default defineComponent({
  name: 'phoneDemo',
  components: {
    phoneBarTime
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  // ----------------生命周期-----------------------
  setup() {
    const phoneBarTimeRef = ref();
    const state = reactive({
      dialoutNumber: '',
      renderMap: {
        PSTN_INVALID: ['dialout'],
        PSTN_INCOMING_CALLING: ['hangup'],
        PSTN_DIALOUT_CALLING: ['hangup'],
        PSTN_INCOMING_RING: ['hangup'],
        PSTN_DIALOUT_RING: ['hangup'],
        PSTN_INCOMING_LINK: ['hangup', 'hold', 'mute'],
        PSTN_DIALOUT_LINK: ['hangup', 'hold', 'mute'],
        PSTN_INCOMING_MUTE: ['mutecancel'],
        PSTN_DIALOUT_MUTE: ['mutecancel'],
        PSTN_INCOMING_HOLD: ['holdcancel'],
        PSTN_DIALOUT_HOLD: ['holdcancel'],
        PSTN_INCOMING_ARRANGE: ['dialout'],
        PSTN_DIALOUT_ARRANGE: ['dialout'],
        //
        SIP_INVALID: ['dialout'],
        SIP_INCOMING_CALLING: ['hangup'],
        SIP_DIALOUT_CALLING: ['hangup'],
        SIP_INCOMING_RING: ['hangup'],
        SIP_DIALOUT_RING: ['hangup'],
        SIP_INCOMING_LINK: ['hangup', 'hold', 'mute'],
        SIP_DIALOUT_LINK: ['hangup', 'hold', 'mute'],
        SIP_INCOMING_MUTE: ['mutecancel'],
        SIP_DIALOUT_MUTE: ['mutecancel'],
        SIP_INCOMING_HOLD: ['holdcancel'],
        SIP_DIALOUT_HOLD: ['holdcancel'],
        SIP_INCOMING_ARRANGE: ['dialout'],
        SIP_DIALOUT_ARRANGE: ['dialout'],
        //
        WEBRTC_INVALID: ['dialout', 'disconnect'],
        WEBRTC_INCOMING_CALLING: ['hangup'],
        WEBRTC_DIALOUT_CALLING: ['hangup'],
        WEBRTC_INCOMING_RING: ['hangup', 'answer'],
        WEBRTC_DIALOUT_RING: ['hangup'],
        WEBRTC_INCOMING_LINK: ['hangup', 'hold', 'mute', 'key'],
        WEBRTC_DIALOUT_LINK: ['hangup', 'hold', 'mute', 'key'],
        WEBRTC_INCOMING_MUTE: ['mutecancel'],
        WEBRTC_DIALOUT_MUTE: ['mutecancel'],
        WEBRTC_INCOMING_HOLD: ['holdcancel'],
        WEBRTC_DIALOUT_HOLD: ['holdcancel'],
        WEBRTC_INCOMING_ARRANGE: ['dialout'],
        WEBRTC_DIALOUT_ARRANGE: ['dialout'],
        WEBRTC_DISCONNECTED: ['connect']
      },
      currentStatus: 'PSTN_INVALID',
      currentStatusName: '空闲',
      keyList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'],
      loginType: '',
      loginTypeList: [{
        value: 'PSTN',
        label: '手机'
      }, {
        value: 'SIP',
        label: 'SIP话机'
      }, {
        value: 'WEBRTC',
        label: 'webrtc'
      }],
      agentStatusList: [],
      agentStatusNumber: '',
      loginForm: {
        accountId: '',
        agentNumber: '',
        password: ''
      }
    });
    const dialout = () => {
      if (!state.dialoutNumber) {
        Message.error('请填写外呼号码');
        return;
      }
      if (window.SPhone) {
        window.SPhone.callApi.dialout({
          calleeNumber: state.dialoutNumber,
          success: function (res) {
            Message.success('外呼成功');
          },
          fail: function (error) {
            Message.error(error.message);
          }
        });
      }
    };
    const hangup = () => {
      if (window.SPhone) {
        window.SPhone.callApi.hangup({
          fail: function (error) {
            console.log(error, 'error');
          }
        });
      }
    };
    const holdOrUnHold = type => {
      if (window.SPhone) {
        window.SPhone.callApi.holdOrUnHold({
          type: type,
          fail: function (error) {
            console.log(error, 'error');
          }
        });
      }
    };
    const muteOrUnMute = type => {
      if (window.SPhone) {
        window.SPhone.callApi.muteOrUnMute({
          type: type,
          fail: function (error) {
            console.log(error, 'error');
          }
        });
      }
    };
    const accept = () => {
      window.SPhone.webPhoneApi.accept();
    };
    const sendDTMF = key => {
      window.SPhone.webPhoneApi.sendDTMF(key);
    };
    const connect = () => {
      window.SPhone.webPhoneApi.connect();
    };
    const disconnect = () => {
      window.SPhone.webPhoneApi.disconnect();
    };
    const keyDowndialout = event => {
      if (event.keyCode === 13) {
        dialout();
      }
    };
    const getQueueOnlineAgents = () => {
      window.SPhone.agentApi.getQueueOnlineAgents({
        success: res => {
          console.log(res);
        },
        fail: () => {
          console.log('fail');
        }
      });
    };
    const updateLoginType = type => {
      window.SPhone.agentApi.updateLoginType({
        loginType: type,
        success: () => {
          state.loginType = type;
          updateCurrentStatus();
          Message.success('切换成功');
        },
        fail: () => {
          Message.error('切换失败');
        }
      });
    };
    const getAgentPhoneBarList = () => {
      window.SPhone.agentApi.getAgentPhoneBarList({
        success: res => {
          state.agentStatusList = res.data;
        }
      });
    };
    const updateAgentStatus = () => {
      window.SPhone.agentApi.updateAgentStatus({
        statusNumber: state.agentStatusNumber
      });
    };
    const agentOffline = () => {
      window.SPhone.agentApi.offline({
        toAnswerOffline: 0
      });
    };
    // const getIvrList = () => {
    //   window.SPhone.agentApi.getIvrList({
    //     statusNumber: state.agentStatusNumber
    //   })
    // }
    // const getAvailableSipNumberList = () => {
    //   window.SPhone.agentApi.getAvailableSipNumberList({
    //     success: (res: any) => {
    //       // SIP话机模式需要绑定的number参数可以从这里获取
    //     }
    //   })
    // }
    const isVisibleBtn = type => {
      if (state.currentStatus) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore"
        return state.renderMap[state.currentStatus] && state.renderMap[state.currentStatus].indexOf(type) > -1;
      }
    };
    const getCurrentStatus = (statusNumber, type) => {
      const callType = type ? '_' + type.toUpperCase() : '';
      let statusName = '';
      switch (statusNumber) {
        case '0':
          statusName = state.loginType + '_INVALID';
          break;
        case '1':
          statusName = state.loginType + callType + '_BUSY';
          break;
        case '2':
          statusName = state.loginType + callType + '_CALLING';
          break;
        case '3':
          statusName = state.loginType + callType + '_RING';
          break;
        case '4':
          statusName = state.loginType + callType + '_LINK';
          break;
        case '5':
          statusName = state.loginType + callType + '_ARRANGE';
          break;
        case '6':
          statusName = state.loginType + callType + '_HOLD';
          break;
        case '7':
          statusName = state.loginType + callType + '_MUTE';
          break;
        case '8':
          statusName = state.loginType + callType + '_DISCONNECTED';
          break;
        default:
          statusName = state.loginType + callType + '_BUSY';
          break;
      }
      return statusName;
    };
    const updateCurrentStatus = () => {
      const statusList = state.currentStatus.split('_');
      if (statusList[0] !== state.loginType) {
        statusList[0] = state.loginType;
        state.currentStatus = statusList.join('_');
      }
    };
    /**
     * 控制计时器
     * @param {string} type timing 正计时 or countDown 倒计时
     * @param {?string} timestamp 时间戳 从哪个时间开始记时 默认为函数执行时间
     */
    const timeRecording = (type, timestamp) => {
      let second = 0;
      if (timestamp) {
        const beginDate = new Date(timestamp);
        const endDate = new Date();
        const diff = endDate.getTime() - beginDate.getTime();
        second = diff / 1000;
      }
      nextTick(() => {
        phoneBarTimeRef.value.timerTypeChange({
          timeType: type,
          statusTime: second
        });
      });
    };
    const handleLogin = () => {
      const queryStr = window.location.search.replace('?', '');
      const queryArr = queryStr.split('&');
      const queryObj = {};
      queryArr.forEach(item => {
        const key = item.split('=')[0];
        const val = item.split('=')[1];
        queryObj[key] = val;
      });
      state.loginForm = Object.assign({}, state.loginForm, queryObj);
    };
    const initSdk = () => {
      window.SPhone = new SPhone({
        server: 'https://kf.dev1.xinmuai.net/api',
        accountId: state.loginForm.accountId,
        agentNumber: state.loginForm.agentNumber,
        loginType: 'WEBRTC',
        password: state.loginForm.password
      });
      window.SPhone.attachEvent({
        success: msg => {
          getAgentPhoneBarList(); // 获取电话条状态配置
          // 暂无 getAvailableSipNumberList() // 切换sip话机的时候，获取可动态绑定的sip号列表
          phoneBarTimeRef.value.timerTypeChange({
            timeType: 'timing',
            statusTime: 0
          });
        },
        error: error => {
          console.error(error);
        },
        message: res => {
          const events = res.event;
          console.log(res.event);
          if (res.type === 'kickOff') {
            Message({
              message: '您当前的会话已经失效,导致该问题的原因是别的座席使用相同的帐号登录了',
              type: 'error',
              duration: 5 * 1000
            });
            return;
          } else if (res.type === 'callStatus') {
            state.currentStatusName = events.statusName;
            const timeType = events.statusNumber === '5' ? 'countDown' : 'timing';
            if (events.statusTime) {
              timeRecording(timeType, events.statusTime);
            } else {
              timeRecording(timeType);
            }
          } else if (res.type === 'loginStatus') {
            state.loginType = events.loginType || 'WEBRTC';
          }
          state.currentStatus = getCurrentStatus(events.statusNumber, events.callType);
        }
      });
    };
    onMounted(async () => {
      await handleLogin();
      await initSdk();
    });
    return {
      ...toRefs(state),
      dialout,
      hangup,
      holdOrUnHold,
      muteOrUnMute,
      accept,
      sendDTMF,
      connect,
      disconnect,
      keyDowndialout,
      getQueueOnlineAgents,
      updateLoginType,
      getAgentPhoneBarList,
      updateAgentStatus,
      agentOffline,
      isVisibleBtn,
      getCurrentStatus,
      updateCurrentStatus,
      timeRecording,
      phoneBarTimeRef
    };
  }
});