/*
 * @Author: cuixuecheng
 * @Date: 2023-01-30 09:31:55
 * @LastEditors: cuixuecheng
 * @LastEditTime: 2023-02-18 19:06:26
 * @Description:
 */
import { createApp } from 'vue'

import App from '@/App.vue'

import 'element-plus/dist/index.css'

const app = createApp(App)
app.mount('#app')
