import { defineComponent, reactive, toRefs, onMounted } from 'vue';
export default defineComponent({
  name: 'phoneBarTime',
  components: {},
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  // ----------------生命周期-----------------------
  setup() {
    const state = reactive({
      phoneBarTimer: 0,
      timing: '00:00:00',
      currentTime: 0 // 当前计时
    });

    const timerTypeChange = obj => {
      // 检测当前是正计时还是倒计时,由父组件调用
      state.currentTime = parseInt(obj.statusTime, 10) || 0;
      if (obj.timeType === 'timing') {
        // 正计时
        timingInterval();
      } else if (obj.timeType === 'countDown') {
        // 倒计时
        countDownInterval();
      }
    };
    const timingInterval = () => {
      // 正计时
      if (state.phoneBarTimer !== null) {
        window.clearInterval(state.phoneBarTimer);
        state.phoneBarTimer = window.setInterval(() => {
          state.timing = ctiUiGetTimer(state.currentTime);
          state.currentTime = state.currentTime - 0 + 1;
        }, 1000);
      }
    };
    const countDownInterval = () => {
      // 话后整理，倒计时
      let autoBusyTime = 30;
      autoBusyTime = parseInt(autoBusyTime, 10) - state.currentTime || 0; // 话后整理时长 -  服务端跑的时间(eg: 后处理读秒的时候，刷新系统)
      if (autoBusyTime < 1) {
        return;
      }
      if (state.phoneBarTimer !== null) {
        window.clearInterval(state.phoneBarTimer); // 清除定时器
      }

      state.phoneBarTimer = window.setInterval(() => {
        autoBusyTime--;
        if (autoBusyTime <= 1) {
          // 倒计时为0的时候，让正计时也为0
          state.currentTime = 0;
        }
        state.timing = ctiUiAutoBusyTime(autoBusyTime);
      }, 1000);
    };
    const ctiUiGetTimer = countTimer => {
      let minute;
      let hour;
      countTimer = 1 + parseInt(countTimer, 10);
      hour = countTimer / 3600;
      hour = parseInt(hour, 10);
      minute = countTimer % 3600 / 60;
      minute = parseInt(minute, 10);
      const second = countTimer % 3600 % 60;
      let mtime = hour < 10 ? '0' + hour : hour;
      mtime += ':';
      mtime += minute < 10 ? '0' + minute : minute;
      mtime += ':';
      mtime += second < 10 ? '0' + second : second;
      return mtime;
    };
    const ctiUiAutoBusyTime = autoBusyTime => {
      let minute;
      let second;
      let hour;
      if (autoBusyTime >= 60 * 60) {
        hour = autoBusyTime / (60 * 60);
        hour = parseInt(hour, 10);
        minute = (autoBusyTime - hour * (60 * 60)) / 60;
        minute = parseInt(minute, 10);
        second = autoBusyTime - hour * (60 * 60) - minute * 60;
      } else if (autoBusyTime >= 60 && autoBusyTime < 60 * 60) {
        hour = '0';
        minute = autoBusyTime / 60;
        minute = parseInt(minute, 10);
        second = autoBusyTime - minute * 60;
      } else if (autoBusyTime > 0 && autoBusyTime < 60) {
        hour = '0';
        minute = '0';
        second = autoBusyTime;
      } else if (autoBusyTime <= 0) {
        hour = '0';
        minute = '0';
        second = '0';
      }
      if (hour < 0) {
        hour = 0;
      }
      if (minute < 0) {
        minute = 0;
      }
      if (second < 0) {
        second = 0;
      }
      const timeStr = (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute) + ':' + (second < 10 ? '0' + second : second);
      return timeStr;
    };
    const clearPhonebarTimer = () => {
      window.clearInterval(state.phoneBarTimer);
    };
    const beforeDestory = () => {
      clearPhonebarTimer();
    };
    onMounted(() => {
      console.log('挂载成功');
    });
    return {
      ...toRefs(state),
      beforeDestory,
      timerTypeChange
    };
  }
});